import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudGetOne, UserMenu, MenuItemLink } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';

class AppMenu extends Component {
    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.fetchProfile();
        }
    }

    fetchProfile = () => {
        this.props.crudGetOne(
            // The resource
            'profile',
            // The id of the resource item to fetch
            localStorage.getItem('uid'),
            // The base path. Mainly used on failure to fetch the data
            '/profile',
            // Wether to refresh the current view. I don't need it here
            false
        );
    };

    render() {
        const { crudGetOne, profile, ...props } = this.props;
        
        return (
            <UserMenu label={profile ? profile.user.email : ''} {...props}>
                <MenuItemLink
                    to="/profile"
                    primaryText="Profile"
                    leftIcon={<PersonIcon />}
                />
            </UserMenu>
        );
    }
}

const mapStateToProps = state => {
    const resource = 'profile';

    const profileState = state.admin.resources[resource];

    return {
        profile: profileState
            ? profileState.data[localStorage.getItem('uid')]
            : null
    };
};

const Menu = connect(
    mapStateToProps,
    { crudGetOne }
)(AppMenu);
export default Menu;
