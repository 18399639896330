import React from 'react';
import { Admin, Resource  } from 'react-admin';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssessmentIcon from '@material-ui/icons/Assessment';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import theme from './theme';
import routes from './utilities/routes';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProviders';
import NotFound from './components/NotFound';
import LoginLayout from './layouts/LoginLayout';
import { AppLayout } from './layouts/AppLayout/Layout'

import UserList from './modules/User/List';
import { Dashboard } from './modules/Dashboard';
import UserCreate from './modules/User/Create';
import SubscriptionTypeCreate from './modules/SubscriptionType/Create';
import SubscriptionTypeList from './modules/SubscriptionType/List';
import SubscriptionList from './modules/Subscription/List';
import AdCreate from './modules/Ad/Create';
import AdList from './modules/Ad/List';
import ReportList from './modules/Report/List';
import UserVerificationList from './modules/UserVerification/List';

const App = () => {

  return (
    <Admin
      title="Kaysiao Admin Portal"
      theme={theme}
      loginPage={LoginLayout}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      customRoutes={routes}
      catchAll={NotFound}
      layout={AppLayout}
      customRoutes={routes}
      disableTelemetry
    >
      <Resource name="profile" />
      <Resource 
        icon={PeopleAltIcon} 
        name="users" 
        options={{ label: 'Users' }} 
        list={UserList}
        create={UserCreate} 
      />
      <Resource 
        icon={MonetizationOnIcon} 
        name="subscriptions" 
        options={{ label: 'Subscriptions' }} 
        list={SubscriptionList}
      />
      <Resource 
        icon={SubscriptionsIcon} 
        name="st" 
        options={{ label: 'Subscription Types' }} 
        list={SubscriptionTypeList}
        create={SubscriptionTypeCreate} 
      />
      <Resource 
        icon={AttachMoneyIcon} 
        name="ads" 
        options={{ label: 'Ads' }} 
        list={AdList}
        create={AdCreate} 
      />
      <Resource 
        icon={AssessmentIcon} 
        name="reports" 
        options={{ label: 'Reports' }} 
        list={ReportList}
      />
      <Resource 
        icon={VerifiedUserIcon} 
        name="uv" 
        options={{ label: 'User Verifications' }} 
        list={UserVerificationList}
      />
    </Admin>
  );
};

export default App;
