import React, { useState }  from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    SearchInput,
    Filter,
    FormWithRedirect,
    TabbedShowLayout,
    Tab,
    TextInput,
    SaveButton,
    required,
    useDataProvider,
    useRedirect,
    useNotify,
    useRefresh,
    ImageInput,
    ImageField,
    FunctionField,
    SelectInput,
    DateTimeInput
} from 'react-admin';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Tooltip,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import dateFormatter from '../../utilities/dateFormatter'
import constants from '../../utilities/constants'

const useStyles = makeStyles((theme) => ({
    dangerButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    deactivateButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    activateButton: {
        color: 'white',
        backgroundColor: 'teal'
    },
    editButton: {
        color: 'white',
        backgroundColor: '#fbc02d'
    },
    media: {
        height: 480
    }
  }));

const AdFilter = (props) => {
    return ( 
        <Filter {...props}>

            <SearchInput 
                fullWidth
                source="q" 
                alwaysOn 
                color="primary"
                variant="outlined"
            />
        </Filter>
    );
};

const CustomShowButton = data => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        data?.record?.image_file && <>
            <Tooltip title="Show Ad Image">
                <Button 
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                >
                    <VisibilityIcon />  
                </Button>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Ad Details"
            >
                <Card className={classes.root}>
                    <CardMedia
                        className={classes.media}
                        image={data.record.image_file + `?jwt=${localStorage.access_token}`}
                        title={data.record.name}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {data.record.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {data.record.description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            onClick={handleCloseClick}
                            variant="contained"
                            startIcon={<CloseIcon />}
                            className={classes.dangerButton}
                        >
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
        </> || <></>
    );
};

const CustomEditButton = data => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSaveAd = values => {
        values.start_date = dateFormatter(values.start_date)
        values.end_date = dateFormatter(values.end_date)
        dataProvider
        .update('ads', { id: data.record.id, data: { meta: values }} )
        .then( response => {
            setShowDialog(false);
            notify("Successfully updated the ad", { type: 'success' });
            redirect('/ads');
            refresh();
            setLoading(false);
        })
        .catch( error => {
            notify(error.message, { type: 'error' });
            setLoading(false);
        });
    };

    const handleSaveAdImage = values => {
        const formData = new FormData();
        formData.append('image', values.image.rawFile)
        dataProvider
        .updateAdImage('ads', { id: data.record.id, data: formData} )
        .then( response => {
            setShowDialog(false);
            notify("Successfully updated the ad", { type: 'success' });
            redirect('/ads');
            refresh();
            setLoading(false);
        })
        .catch( error => {
            notify(error.message, { type: 'error' });
            setLoading(false);
        });
    };

    return (
        <>
            <Tooltip title="Edit">
                <Button 
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    className={classes.editButton}
                >
                    <EditIcon />  
                </Button>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Ad Update"
            >

                <TabbedShowLayout>
                    <Tab
                        label="Ad Details Modification"
                    >
                        <FormWithRedirect
                            resource="ads"
                            save={handleSaveAd}
                            render={
                                (
                                    {
                                        handleSubmitWithRedirect,
                                        pristine,
                                        saving
                                    }
                                ) => (
                                    <>
                                        <DialogContent>
                                        <TextInput 
                                                source="name" 
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.name}
                                            />
                                            <TextInput 
                                                source="description" 
                                                multiline
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.description}
                                            />
                                            <DateTimeInput 
                                                label="Start Date"
                                                source="start_date" 
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.start_date}
                                                options={{ format: 'DD/MM/YYYY HH:MM:SS', ampm: false, clearable: true }}
                                            />
                                            <DateTimeInput 
                                                label="End Date"
                                                source="end_date" 
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.end_date}
                                                options={{ format: 'DD/MM/YYYY HH:MM:SS', ampm: false, clearable: true }}
                                            />
                                            <TextInput 
                                                source="redirect_url" 
                                                multiline
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.redirect_url}
                                            />
                                            <SelectInput 
                                                source="status" 
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.status}
                                                choices={constants.adStatus}
                                            />
                                        </DialogContent>
                                        
            
                                        <DialogActions>
                                            <SaveButton
                                                handleSubmitWithRedirect={
                                                    handleSubmitWithRedirect
                                                }
                                                pristine={pristine}
                                                saving={saving}
                                                disabled={loading}
                                            />
                                            <Button
                                                onClick={handleCloseClick}
                                                variant="contained"
                                                startIcon={<CloseIcon />}
                                                className={classes.dangerButton}
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>   
                                        
                                    </>
                                )
                            }
                        /> 
                    </Tab>
                    <Tab
                        label="Ad Image Modification"
                    >
                        <FormWithRedirect
                            resource="ads"
                            save={handleSaveAdImage}
                            render={
                                (
                                    {
                                        handleSubmitWithRedirect,
                                        pristine,
                                        saving
                                    }
                                ) => (
                                    <>
                                        <DialogContent>
                                            <ImageInput
                                                source="image"
                                                accept="image/*"
                                                maxSize={1 * 1024 * 1024}
                                                placeholder="Drop Ad Image"
                                                label="Ad Image"
                                            >
                                                <ImageField source="src" title="title" />
                                            </ImageInput>
                                        </DialogContent>
                                        <DialogActions>
                                            <SaveButton
                                                handleSubmitWithRedirect={
                                                    handleSubmitWithRedirect
                                                }
                                                pristine={pristine}
                                                saving={saving}
                                                disabled={loading}
                                            />
                                            <Button
                                                onClick={handleCloseClick}
                                                variant="contained"
                                                startIcon={<CloseIcon />}
                                                className={classes.dangerButton}
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>   
                                        
                                    </>
                                )
                            }
                        /> 
                    </Tab>
                </TabbedShowLayout>
            </Dialog>
        </>
    );
};

export const AdList = props => {

    return (
        <List {...props} filters={<AdFilter />} perPage={25} title="Ads List" bulkActionButtons={false} >
            <Datagrid>
                <TextField 
                    source="name"     
                />
                <TextField 
                    source="description" 
                />
                <TextField 
                    source="start_date" 
                    label="Start Date"    
                />
                <TextField 
                    source="end_date" 
                    label="End Date"    
                />
                <TextField 
                    source="redirect_url" 
                    label="Redirect URL"    
                />
                <TextField 
                    source="status" 
                    label="Status"    
                />
                <FunctionField
                    render={record => (record.status && constants.adStatus[record.status - 1].name)}
                />
                <CustomEditButton />
                <CustomShowButton />
            </Datagrid>
        </List>
    );
}

export default AdList;