import React, { useState }  from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    SearchInput,
    Filter,
    FormWithRedirect,
    TextInput,
    NumberInput,
    SelectInput,
    SaveButton,
    required,
    useDataProvider,
    useRedirect,
    useNotify,
    useRefresh
} from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Tooltip,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    dangerButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    deactivateButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    activateButton: {
        color: 'white',
        backgroundColor: 'teal'
    },
    editButton: {
        color: 'white',
        backgroundColor: '#fbc02d'
    },
  }));

const SubscriptionFilter = (props) => {
    return ( 
        <Filter {...props}>

            <SearchInput 
                fullWidth
                source="q" 
                alwaysOn 
                color="primary"
                variant="outlined"
            />
        </Filter>
    );
};

const CustomEditButton = data => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSave = values => {
        dataProvider
        .update('subscriptions', { id: data.record.subscription.code, data: { meta: values }} )
        .then( response => {
            setShowDialog(false);
            notify("Successfully updated the user", { type: 'success' });
            redirect('/subscriptions');
            refresh();
            setLoading(false);
        })
        .catch( error => {
            notify(error.message, { type: 'error' });
            setLoading(false);
        });
    };

    return (
        <>
            <Tooltip title="Edit">
                <Button 
                    onClick={handleClick}
                    variant="contained"
                     color="primary"
                    className={classes.editButton}
                >
                    <EditIcon />  
                </Button>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Subscription Type Update"
            >
                <DialogTitle id="dialog-title">{`Subscription Modification (${data.record.subscription.code})`}</DialogTitle>
                <FormWithRedirect
                    resource="subscriptions/types"
                    save={handleSave}
                    render={
                        (
                            {
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }
                        ) => (
                            <>
                                <DialogContent>
                                    <SelectInput
                                        fullWidth
                                        source="status"
                                        choices={[
                                            { id: 'created', name: 'Created' },
                                            { id: 'partially-paid', name: 'Partially Paid' },
                                            { id: 'paid', name: 'Paid' },
                                            { id: 'voided', name: 'Voided' },
                                            { id: 'expired', name: 'Expired' },
                                            { id: 'failed', name: 'Failed' },
                                            { id: 'suspended', name: 'Suspended' },
                                        ]}
                                        initialValue={data.record.subscription.status}
                                        validate={[required()]}
                                    />
                                </DialogContent>
                                
    
                                <DialogActions>
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                    />
                                    <Button
                                        onClick={handleCloseClick}
                                        variant="contained"
                                        startIcon={<CloseIcon />}
                                        className={classes.dangerButton}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>   
                                
                            </>
                        )
                    }
                /> 
            </Dialog>
        </>
    );
};

export const SubscriptionList = props => {

    return (
        <List {...props} filters={<SubscriptionFilter />} perPage={25} title="Subscription List" bulkActionButtons={false} >
            <Datagrid>
                <TextField 
                    source="user.uid" 
                    label="User ID"    
                />
                <TextField 
                    source="subscription.code" 
                    label="Code"    
                />
                <TextField 
                    source="subscription.payment_method" 
                    label="Payment Method"    
                />
                <TextField 
                    source="subscription.amount" 
                    label="Amount"    
                />
                <TextField 
                    source="subscription.convenience_fee" 
                    label="Convenience Fee"    
                />
                <TextField 
                    source="subscription.partner_fee" 
                    label="Partner Fee"    
                />
                <TextField 
                    source="subscription.start_date" 
                    label="Start Date"    
                />
                <TextField 
                    source="subscription.end_date" 
                    label="End Date"    
                />
                <TextField 
                    source="subscription.payment_date" 
                    label="Payment Date"    
                />
                <TextField 
                    source="subscription.status" 
                    label="Status"    
                />
                <CustomEditButton />
            </Datagrid>
        </List>
    );
}

export default SubscriptionList;