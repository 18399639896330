import React from "react";
import { 
    Create,
    SimpleForm, 
    TextInput, 
    useNotify,
    useRefresh,
    useRedirect,
    required,
    SelectInput,
    DateTimeInput
} from 'react-admin';
import dateFormatter from '../../utilities/dateFormatter'
import constants from '../../utilities/constants'

export const AdCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = ({ data }) => {
        notify("Successfully Added an Ad", { type: 'success' })
        redirect('/ads');
        refresh();
    };

    const transform = data => {
        data.start_date = dateFormatter(data.start_date)
        data.end_date = dateFormatter(data.end_date)
        return { meta: data }
    }

    return (
        <Create onSuccess={onSuccess} transform={transform} submitOnEnter={false} {...props}>
            <SimpleForm>
                <TextInput 
                    source="name" 
                    validate={[required()]}
                    fullWidth 
                />
                <TextInput 
                    source="description" 
                    multiline
                    validate={[required()]}
                    fullWidth 
                />
                <DateTimeInput 
                    label="Start Date"
                    source="start_date" 
                    validate={[required()]}
                    fullWidth 
                    options={{ format: 'DD/MM/YYYY HH:MM:SS', ampm: false, clearable: true }}
                />
                <DateTimeInput 
                    label="End Date"
                    source="end_date" 
                    validate={[required()]}
                    fullWidth 
                    options={{ format: 'DD/MM/YYYY HH:MM:SS', ampm: false, clearable: true }}
                />
                <TextInput 
                    source="redirect_url" 
                    multiline
                    validate={[required()]}
                    fullWidth 
                />
                <SelectInput 
                    source="status" 
                    validate={[required()]}
                    fullWidth 
                    initialValue={1}
                    choices={constants.adStatus}
                />
            </SimpleForm>
        </Create>
    );
};

export default AdCreate;