import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import UserCount from './UserCount'
import LoginCount from './LoginCount'
import PremiumUserCount from './PremiumUserCount'
import SubscriptionIncome from './SubscriptionIncome'
import ChartLast30Days from './ChartLast30Days'
import Welcome from './Welcome';


const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;
const newUsersTitle = "30 Day New Users Acquired"
const loginsTitle = "30 Day Login Activities"

const Dashboard = () => {
    const [state, setState] = useState({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    );

    const fetchAnalyticalData = useCallback(async () => {
        const { data: userCount, data } = await dataProvider.getUserCount(
            'analytics',
            {
                filter: { status: 'all' }
            }
        );

        const { data: loginCount } = await dataProvider.getLoginCount(
            'analytics',
            {
                filter: { status: 'all' }
            }
        );
        const { data: premiumUserCount } = await dataProvider.getSubscribedUserCount(
            'analytics',
            {}
        );
        const { data: subscriptionIncome } = await dataProvider.getSubscriptionIncome(
            'analytics',
            {}
        );
        const { data: userCountLast30Days } = await dataProvider.getUserCountByCoverage(
            'analytics',
            {
                filter: {
                    coverage: 'last-30-days'
                }
            }
        );
        const { data: loginCountLast30Days } = await dataProvider.getLoginCountByCoverage(
            'analytics',
            {
                filter: {
                    coverage: 'last-30-days'
                }
            }
        );
        setState(state => ({
            ...state,
            userCount,
            loginCount,
            premiumUserCount,
            subscriptionIncome,
            userCountLast30Days,
            loginCountLast30Days,
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchAnalyticalData();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        userCount,
        loginCount,
        premiumUserCount,
        subscriptionIncome,
        userCountLast30Days,
        loginCountLast30Days,
    } = state;
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn}>
                <Welcome />
                <UserCount value={userCount} />
                <VerticalSpacer />
                <LoginCount value={loginCount} />
                <VerticalSpacer />
                <PremiumUserCount value={premiumUserCount} />
                <VerticalSpacer />
                <SubscriptionIncome value={subscriptionIncome} />
            </div>
            <div style={styles.singleCol}>
                <ChartLast30Days title={newUsersTitle} chartData={userCountLast30Days} />
            </div>
            <div style={styles.singleCol}>
                <ChartLast30Days title={loginsTitle} chartData={loginCountLast30Days} />
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn}>
            <div style={styles.singleCol}>
                <Welcome />
            </div>
            <div style={styles.flex}>
                <UserCount value={userCount} />
                <Spacer />
                <LoginCount value={loginCount} />
                <Spacer />
                <PremiumUserCount value={premiumUserCount} />
                <Spacer />
                <SubscriptionIncome value={subscriptionIncome} />
            </div>
            <div style={styles.singleCol}>
                <ChartLast30Days title={newUsersTitle} chartData={userCountLast30Days} />
            </div>
            <div style={styles.singleCol}>
                <ChartLast30Days title={loginsTitle} chartData={loginCountLast30Days} />
            </div>
        </div>
    ) : (
        <>
            <Welcome />
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <UserCount value={userCount} />
                        <Spacer />
                        <LoginCount value={loginCount} />
                        <Spacer />
                        <PremiumUserCount value={premiumUserCount} />
                        <Spacer />
                        <SubscriptionIncome value={subscriptionIncome} />
                    </div>
                    <div style={styles.singleCol}>
                        <ChartLast30Days title={newUsersTitle} chartData={userCountLast30Days} />
                    </div>
                    <div style={styles.singleCol}>
                        <ChartLast30Days title={loginsTitle} chartData={loginCountLast30Days} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;