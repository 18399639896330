import genericDataProvider from './genericDataProvider';
import profileDataProvider from './profileDataProvider';
import subscriptionTypeDataProvider from './subscriptionTypeDataProvider';
import reportDataProvider from './reportDataProvider';
import userVerificationDataProvider from './userVerificationDataProvider';
import analyticsProvider from './analyticsProvider'
import { 
    GET_LIST, 
    GET_ONE, 
    GET_MANY_REFERENCE, 
    GET_MANY, UPDATE, 
    UPDATE_MANY, 
    CREATE, 
    DELETE, 
    DELETE_MANY 
} from 'react-admin';

const dataProviders = {
    'profile': {
        dataProvider: profileDataProvider
    },
    'users': {
        dataProvider: genericDataProvider
    },
    'st': {
        dataProvider: subscriptionTypeDataProvider
    },
    'subscriptions': {
        dataProvider: genericDataProvider
    },
    'ads': {
        dataProvider: genericDataProvider
    },
    'reports': {
        dataProvider: reportDataProvider
    },
    'uv': {
        dataProvider: userVerificationDataProvider
    },
    'analytics': {
        dataProvider: analyticsProvider
    },
}

export default (type, resource, params) => {
    var dataProviderMapping;
    try {
        dataProviderMapping = dataProviders[resource].dataProvider;
    } catch (e) {
        throw new Error('resource is not yet registered in the data provider');
    }
    
    switch(type) {
        case GET_LIST:
            return dataProviderMapping.getList(resource, params);
        case GET_ONE:
            return dataProviderMapping.getOne(resource, params);
        case GET_MANY_REFERENCE:
            return dataProviderMapping.getManyReference(resource, params);
        case GET_MANY:
            return dataProviderMapping.getMany(resource, params);
        case UPDATE:
            return dataProviderMapping.update(resource, params);
        case UPDATE_MANY:
            return dataProviderMapping.updateMany(resource, params);   
        case CREATE:
            return dataProviderMapping.create(resource, params);
        case DELETE:
            return dataProviderMapping.delete(resource, params);
        case DELETE_MANY:
            return dataProviderMapping.deleteMany(resource, params);
        case 'updateEmail':
            return dataProviderMapping.updateEmail(resource, params);
        case 'updatePassword':
            return dataProviderMapping.updatePassword(resource, params);
        case 'updateAdImage':
            return dataProviderMapping.updateAdImage(resource, params);
        case 'getUserCount':
            return dataProviderMapping.getUserCount(resource, params);
        case 'getLoginCount':
            return dataProviderMapping.getLoginCount(resource, params);
        case 'getSubscribedUserCount':
            return dataProviderMapping.getSubscribedUserCount(resource, params);
        case 'getSubscriptionIncome':
            return dataProviderMapping.getSubscriptionIncome(resource, params);
        case 'getUserCountByCoverage':
            return dataProviderMapping.getUserCountByCoverage(resource, params);
        case 'getLoginCountByCoverage':
            return dataProviderMapping.getLoginCountByCoverage(resource, params);
        default:
            return {
                data: [],
                total: 0,
            }
    }
};