import * as React from 'react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CardWithIcon from './CardWithIcon';

const PremiumUserCount = ({ value }) => {
    return (
        <CardWithIcon
            to="/subscriptions"
            icon={VerifiedUserIcon}
            title="Premium Users"
            subtitle={value}
        />
    );
};

export default PremiumUserCount;