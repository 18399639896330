export default {
    //for dev
    // base_url: 'http://localhost:5000/api',
    // admin_url: 'http://localhost:5000/admin/api',
    //for test
    // base_url: 'http://52.70.156.140/api',
    // admin_url: 'http://52.70.156.140/admin/api',
    //for staging
    // base_url: 'https://apikaysiaopreproduction-env.eba-uhscpmxu.us-east-1.elasticbeanstalk.com/api',
    // admin_url: 'https://apikaysiaopreproduction-env.eba-uhscpmxu.us-east-1.elasticbeanstalk.com/admin/api',
    //for production
    base_url: 'https://app.kaysiao.com/api',
    admin_url: 'https://app.kaysiao.com/admin/api',
    contentTypeJSON: 'application/json',
    adStatus: [
        {id: 1, name: 'Activated'},
        {id: 2, name: 'Deactivated'},
        {id: 3, name: 'Expired'}
    ],
    uvStatus: [
        // {id: 'created', name: 'Created'},
        {id: 'approved', name: 'Approved'},
        {id: 'declined', name: 'Declined'},
        // {id: 'expired', name: 'Expired'}
    ]
}