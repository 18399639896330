import constants from '../utilities/constants';
import httpClient from '../utilities/httpClient';

const apiUrl = `${constants.base_url}`

export default {
    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/profile/details`)
        .then( 
            ( {json} ) => {
                json.meta.id = localStorage.uid;
                return {
                    data: json.meta
                }
            }
        );
    },
    updateEmail: (resource, params) => {
        return httpClient(`${apiUrl}/accounts/email`, {
            method: 'PUT',
            body: JSON.stringify(params),
        })
        .then( 
            ( {json} ) => {
                return {
                    data: json.meta
                }
            }
        );
    },
    updatePassword: (resource, params) => {
        return httpClient(`${apiUrl}/accounts/password`, {
            method: 'PUT',
            body: JSON.stringify(params),
        })
        .then( 
            ( {json} ) => {
                return {
                    data: json.meta
                }
            }
        );
    },
};
