import * as React from "react";
import {
    makeStyles,
    Card,
    Typography,
    CardMedia
} from '@material-ui/core';
import { Title } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    image: {
      width: 180,
      height: 180,
      marginRight: 'auto',
      marginLeft: 'auto'
    }
  }));

export default () => {
    const classes = useStyles();
    return (
        <Card>
            <Title title="Not Found" />
            <CardMedia
                className={classes.image}
                title="Kaysiao Logo"
                image="/logo512.png"
                media="img"
            />
            <br>
            </br>
            <Typography
                variant="h4"
                color="textSecondary"
                align="center"
            >
                404: The page you are looking for isn't here.
            </Typography>
        </Card>
    )
};