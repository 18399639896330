import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  CardMedia
} from '@material-ui/core';
import { useLogin, useNotify, Notification, useRedirect } from 'react-admin';

import Page from '../components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    width: 200,
    height: 170,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  inputTextColor: {
    color: theme.palette.text.secondary
  }
}));

const Login = () => {
  const classes = useStyles();
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();
  
  if (localStorage.access_token) {
    redirect('/');
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = e => {
        e.preventDefault();
        login( {email, password} )
        .then( res => {
            notify("Successful Login.", { type: 'success' });
        })
        .catch( () => {
            notify("Invalid Username and Password.", { type: 'error' });
        });
    };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <CardMedia
            className={classes.image}
            title="Kaysiao Logo"
            image="/logo512.png"
            media="img"
          />
          <Box mb={3}>
            <Typography
              color="primary"
              variant="h2"
              align="center"
            >
              Admin Login
            </Typography>
          </Box>
          <form 
            onSubmit={handleSubmit}
          >
              <TextField
                    fullWidth
                    label="Email Address"
                    margin="normal"
                    name="email
                    "
                    onChange={e => setEmail(e.target.value)}
                    type="text"
                    value={email}
                    variant="outlined"
                    InputProps={{ className: classes.inputTextColor }}
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    value={password}
                    variant="outlined"
                    InputProps={{ className: classes.inputTextColor }}
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Login
                  </Button>
                  </Box>
          </form>
          <Notification
            autoHideDuration={3000}
          />
        </Container>
      </Box>
    </Page>
  );
};

export default Login;