
import { subDays } from 'date-fns';

export const chartDateFormater = (date) => {
    return new Date(date).toLocaleDateString()
}

export const getChartDataPerDay = (data) => {
    return data.map(
        d => (
            {
                date: new Date(d.date).getTime(),
                total: d.total
            }
        )
    )
}

export const getMonthAgo = () => {
    return subDays(new Date(), 30)
}