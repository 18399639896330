import React, { useState }  from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    SearchInput,
    Filter,
    FormWithRedirect,
    TextInput,
    NumberInput,
    SelectInput,
    SaveButton,
    required,
    useDataProvider,
    useRedirect,
    useNotify,
    useRefresh
} from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    dangerButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    deactivateButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    activateButton: {
        color: 'white',
        backgroundColor: 'teal'
    },
    editButton: {
        color: 'white',
        backgroundColor: '#fbc02d'
    },
  }));

const SubscriptionTypeFilter = (props) => {
    return ( 
        <Filter {...props}>

            <SearchInput 
                fullWidth
                source="q" 
                alwaysOn 
                color="primary"
                variant="outlined"
            />
        </Filter>
    );
};

const CustomEditButton = data => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSave = values => {
        dataProvider
        .update('st', { id: data.record.id, data: { meta: values }} )
        .then( response => {
            setShowDialog(false);
            notify("Successfully updated the subscription type", { type: 'success' });
            redirect('/st');
            refresh();
            setLoading(false);
        })
        .catch( error => {
            notify(error.message, { type: 'error' });
            setLoading(false);
        });
    };

    return (
        <>
            <Tooltip title="Edit">
                <Button 
                    onClick={handleClick}
                    variant="contained"
                     color="primary"
                    className={classes.editButton}
                >
                    <EditIcon />  
                </Button>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Subscription Type Update"
            >
                <DialogTitle id="dialog-title">{"Subscription Type Modification"}</DialogTitle>
                <FormWithRedirect
                    resource="subscriptions/types"
                    save={handleSave}
                    render={
                        (
                            {
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }
                        ) => (
                            <>
                                <DialogContent>
                                    <TextInput 
                                        source="name" 
                                        validate={[required()]}
                                        fullWidth 
                                        initialValue={data.record.name}
                                    />
                                    <NumberInput 
                                        source="amount" 
                                        validate={[required()]}
                                        fullWidth 
                                        initialValue={data.record.amount}
                                        min={0}
                                    />
                                    <NumberInput 
                                        source="discounted_amount" 
                                        validate={[required()]}
                                        fullWidth 
                                        initialValue={data.record.discounted_amount}
                                        min={0}
                                    />
                                    {/* <SelectInput
                                        fullWidth
                                        source="measurement"
                                        choices={[
                                            { id: 'day', name: 'Day' },
                                            { id: 'week', name: 'Week' },
                                            { id: 'month', name: 'Month' },
                                            { id: 'year', name: 'Year' },
                                        ]}
                                        initialValue={data.record.measurement ? data.record.measurement.toLowerCase() : 'month'}
                                    />
                                    <NumberInput 
                                        source="value" 
                                        validate={[required()]}
                                        fullWidth 
                                        initialValue={data.record.value}
                                        min={1}
                                    /> */}
                                    <SelectInput
                                        fullWidth
                                        source="status"
                                        choices={[
                                            { id: 'activated', name: 'Activated' },
                                            { id: 'deactivated', name: 'Deactivated' }
                                        ]}
                                        initialValue={data.record.status}
                                        validate={[required()]}
                                    />
                                </DialogContent>
                                
    
                                <DialogActions>
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                    />
                                    <Button
                                        onClick={handleCloseClick}
                                        variant="contained"
                                        startIcon={<CloseIcon />}
                                        className={classes.dangerButton}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>   
                                
                            </>
                        )
                    }
                /> 
            </Dialog>
        </>
    );
};

export const SubscriptionTypeList = props => {

    return (
        <List {...props} filters={<SubscriptionTypeFilter />} perPage={25} title="Subscription Type List" bulkActionButtons={false} >
            <Datagrid>
                <TextField 
                    source="code" 
                    label="Code"    
                />
                <TextField 
                    source="name" 
                    label="Name"    
                />
                <TextField 
                    source="amount" 
                    label="Amount"    
                />
                <TextField 
                    source="discounted_amount" 
                    label="Discounted Amount"    
                />
                <TextField 
                    source="value" 
                    label="Value"    
                />
                <TextField 
                    source="measurement" 
                    label="Measurement"    
                />
                <TextField 
                    source="status" 
                    label="Status"    
                />
                <CustomEditButton />
            </Datagrid>
        </List>
    );
}

export default SubscriptionTypeList;