import { fetchUtils } from 'react-admin';
import constants from './constants';

export default (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: constants.contentTypeJSON, Authorization: `Bearer ${localStorage.access_token}` });
    } else {
        options.headers.append('Accept', constants.contentTypeJSON);
        options.headers.append('Authorization', `Bearer ${localStorage.access_token}`);
    }

    return fetchUtils.fetchJson(url, options);
};