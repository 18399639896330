import { stringify } from 'query-string';
import constants from '../utilities/constants';
import httpClient from '../utilities/httpClient';

const apiUrl = `${constants.admin_url}/users/verifications`

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            limit: page * perPage,
            offset: (page * perPage) - perPage,
            q: params.filter.q,
            id: params.filter.id
        }
        const url = `${apiUrl}?${stringify(query)}`;

        return httpClient(url)
        .then( 
            ( { headers, json }) => 
            {
                return {
                    data: json.meta,
                    total: !headers.get('Content-Range') ? perPage : parseInt(headers.get('Content-Range').split('/').pop(), 10),
                }
            }
        );
    },
    update: (resource, params) =>
        httpClient(`${apiUrl}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: {
                id: params.id,
                ...params.data,
                ...json
            },
        })),
};
