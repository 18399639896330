import constants from '../utilities/constants';

const authProvider = {
    login: (params) => {
        params.device = { id: null, model: null, os: 'web' }
        //params should be email and password
        const request = new Request(`${constants.base_url}/authentication/email`, {
            method: 'POST',
            body: JSON.stringify({meta: params}),
            headers: new Headers({
                'Content-Type': constants.contentTypeJSON
            })
        });

        return fetch(request)
        .then( response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then( ({meta}) => {
            if (meta.role !== 2) {
                throw new Error("The user and password is not authorized to access the page.");
            }

            localStorage.setItem('access_token', meta.access_token);
            localStorage.setItem('refresh_token', meta.refresh_token);
            localStorage.setItem('uid', meta.uid);

            return Promise.resolve();
        });
    },
    logout: (params) => {
        //remove to local storage

        if (localStorage.refresh_token) {
            //params should be email and password
            const request = new Request(`${constants.base_url}/authentication/revoke`, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': constants.contentTypeJSON
                }),
                body: JSON.stringify(
                    {
                        meta: {
                            refresh_token: localStorage.refresh_token
                        }
                    }
                )
            });
    
            fetch(request)
            .then( response => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.resolve()
                }
                return response.json();
            })
            .then( () => {
    
                return Promise.resolve();
            });

        }
            
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('uid');

        return Promise.resolve();
    },
    checkAuth: (params) => {
        return localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject();
    },
    checkError: (error) => {
        
        if (error.status === 401 || error.status === 403) {
            if (!localStorage.getItem('access_token')) {
                return Promise.reject();
            } else {
                
                //params should be email and password
                const request = new Request(`${constants.base_url}/authentication/refresh`, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': constants.contentTypeJSON,
                        'Authorization': `Bearer ${localStorage.refresh_token}`
                    })
                });

                return fetch(request)
                .then( response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then( ({meta}) => {
                    
                    localStorage.setItem('access_token', meta.access_token);
                    localStorage.setItem('refresh_token', meta.refresh_token);

                    return Promise.resolve();
                })
                .catch(
                    () => {

                        return Promise.reject();

                    }
                )
            }
        }
        
        return Promise.resolve();
    },
    getPermissions: (params) => {
        return Promise.resolve();
    }
}

export default authProvider;