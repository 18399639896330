import React, { useState, useContext } from 'react';
import {
    Edit,
    TextInput,
    SimpleForm,
    PasswordInput,
    Toolbar,
    useDataProvider,
    useNotify,
    useRedirect,
    useRefresh,
    DataProviderContext,
} from 'react-admin';
import {
    Button
} from "@material-ui/core";
import {
    makeStyles
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    saveEmailButton: {
        margin: '8px',
        color: theme.palette.text.primary
    },
    savePasswordButton: {
        margin: '8px',
        color: theme.palette.text.secondary
    },
}));

const ProfileEdit = props => {

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();

    const [email, setEmail] = useState('admin@kaysiao.com');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const ProfileEditToolbar = props => {
        const classes = useStyles();
        // const dataProvider = useDataProvider();
        const dataProvider = useContext(DataProviderContext);
        const saveEmail = () => {

            console.log({ meta: { email } })
            dataProvider
                .updateEmail('profile', { meta: { email } })
                .then(response => {
                    notify('The email details has been successfully updated.', { type: 'success' });
                    setLoading(false);
                    redirect('/profile');
                    refresh();
                })
                .catch(error => {
                    notify(error.message, { type: 'error' });
                    setLoading(false);
                });
        }

        const savePassword = () => dataProvider
            .updatePassword('profile', { meta: { password } })
            .then(response => {
                notify('The password details has been successfully updated.', { type: 'success' });
                redirect('/profile');
                refresh();
            })
            .catch(error => {
                notify(error.message, { type: 'error' });
                setLoading(false);
            });

        return (
            <Toolbar {...props} >
                <Button
                    submitOnEnter={false}
                    className={classes.saveEmailButton}
                    variant="outlined"
                    onClick={saveEmail}
                    loading={loading}
                >
                    Save Email
                </Button>

                <Button
                    submitOnEnter={false}
                    className={classes.savePasswordButton}
                    variant="outlined"
                    onClick={savePassword}
                    loading={loading}
                >
                    Save Password
                </Button>
            </Toolbar>
        );

    };

    return (
        <Edit
            id={localStorage.uid}
            resource="profile"
            basePath="/profile"
            redirect={false}
            title="Profile"
            hasEdit={true}
            {...props}
        >
            <SimpleForm toolbar={<ProfileEditToolbar {...props} />}>
                <TextInput
                    fullWidth
                    source="user.email"
                    label="Email"
                    onChange={e => setEmail(e.target.value)}
                    format={v => { setEmail(v); return v; }}
                />
                <PasswordInput
                    fullWidth
                    source="password"
                    onChange={e => setPassword(e.target.value)}
                    format={v => { setPassword(v); return v; }}
                />
            </SimpleForm>
        </Edit>
    );
};

export default ProfileEdit;