import { stringify } from 'query-string';
import constants from '../utilities/constants';
import httpClient from '../utilities/httpClient';

const apiUrl = `${constants.admin_url}/subscriptions/types`

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            limit: page * perPage,
            offset: (page * perPage) - perPage,
            q: params.filter.q,
            id: params.filter.id
        }
        const url = `${apiUrl}?${stringify(query)}`;

        return httpClient(url)
        .then( 
            ( { headers, json }) => 
            {
                return {
                    data: json.meta,
                    total: !headers.get('Content-Range') ? perPage : parseInt(headers.get('Content-Range').split('/').pop(), 10),
                }
            }
        );
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${params.id}`).then(({ json }) => ({
            data: json.meta,
            total: 1
        })),

    getMany: (resource, params) => {
        const url = `${apiUrl}`;
        return httpClient(url).then(({ json }) => ({ data: json.meta }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            limit: page * perPage,
            offset: (page * perPage) - perPage,
        }
        const url = `${apiUrl}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.meta,
            total: !headers.get('Content-Range') ? perPage : parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: {
                id: params.id,
                ...params.data,
                ...json
            },
        })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: {
                id: 1,
                ...params.meta,
                ...json
            },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};
