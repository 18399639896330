import React from "react";
import { 
    Create,
    SimpleForm, 
    TextInput, 
    SelectInput,
    PasswordInput,
    DateInput,
    useNotify,
    useRefresh,
    useRedirect,
    required,
} from 'react-admin';

export const UserCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = ({ data }) => {
        notify("Successfully created a user", { type: 'success' })
        redirect('/users');
        refresh();
    };

    const transform = data => {
        return { meta: data }
    }

    return (
        <Create onSuccess={onSuccess} transform={transform} submitOnEnter={false} {...props}>
            <SimpleForm>
                <TextInput 
                    source="name" 
                    validate={[required()]}
                    fullWidth 
                />
                <TextInput 
                    source="number" 
                    validate={[required()]}
                    fullWidth 
                />
                <TextInput 
                    source="email" 
                    validate={[required()]}
                    fullWidth 
                />
                <PasswordInput 
                    fullWidth
                    source="password"
                    validate={[required()]} 
                />
                <DateInput 
                    source="birthdate" 
                    validate={[required()]}
                    fullWidth 
                />
                <SelectInput
                    fullWidth
                    source="gender"
                    choices={[
                        { id: 'male', name: 'Male' },
                        { id: 'female', name: 'Female' },
                    ]}
                    initialValue="male"
                />
                <SelectInput
                    fullWidth
                    source="role"
                    choices={[
                        { id: 'user', name: 'User' },
                        { id: 'administrator', name: 'Administrator' },
                    ]}
                    initialValue="user"
                />
                <SelectInput
                    fullWidth
                    source="status"
                    choices={[
                        { id: 'activated', name: 'Activated' },
                        { id: 'deactivated', name: 'Deactivated' },
                        { id: 'blocked', name: 'Blocked' },
                        { id: 'deleted', name: 'Deleted' },
                    ]}
                    initialValue="activated"
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;