export default (strDate) => {

    var d = new Date(strDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = '' + d.getFullYear(),
    hour = '' + d.getHours(),
    minutes = '' + d.getMinutes(),
    mm = '' + d.getMilliseconds();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (minutes.length < 2) 
        minutes = '0' + minutes;
    if (mm.length < 2) 
        mm = '0' + mm;

    return [[year, month, day].join('-'), [hour, minutes, mm].join(':')].join(' ');
}