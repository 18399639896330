import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#BD3F32',
      default: '#F5F5F5',
      paper: '#F5F5F5'
    },
    primary: {
      main: '#BD3F32'
    },
    secondary: {
      main: '#3F3D56'
    },
    text: {
      primary: '#BD3F32',
      secondary: '#3F3D56'
    }
  },
  shadows,
  typography
});

export default theme;
