import * as React from 'react';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CardWithIcon from './CardWithIcon';

const SubscriptionIncome = ({ value }) => {
    return (
        <CardWithIcon
            to="/subscriptions"
            icon={MonetizationOnIcon}
            title="Premium Income"
            subtitle={value}
        />
    );
};

export default SubscriptionIncome;