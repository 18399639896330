import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import GlobalStyles from '../../components/GlobalStyles';

import theme from '../../theme';
import Login from '../../components/Login';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const LoginLayout = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
      
        <div className={classes.root}>
            <div className={classes.contentContainer}>
                <div className={classes.content}>
                    <Login />
                </div>
            </div>
        </div>
    </ThemeProvider>
  );
};

export default LoginLayout;