import React from 'react';
import { Route } from 'react-router-dom';
import ProfileEdit from '../modules/Profile/Edit';

export default [
    <Route
        key="profile"
        path="/profile"
        component={ProfileEdit}
    />,
]