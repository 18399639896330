import React, { useState }  from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    SearchInput,
    FunctionField,
    Filter,
    FormWithRedirect,
    TabbedShowLayout,
    Tab,
    TextInput,
    PasswordInput,
    SelectInput,
    DateInput,
    SaveButton,
    required,
    useDataProvider,
    useRedirect,
    useNotify,
    useRefresh
} from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Typography,
    Grid,
    Tooltip
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    dangerButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    deactivateButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    activateButton: {
        color: 'white',
        backgroundColor: 'teal'
    },
    editButton: {
        color: 'white',
        backgroundColor: '#fbc02d'
    },
  }));

const UserFilter = (props) => {
    return ( 
        <Filter {...props}>

            <SearchInput 
                fullWidth
                source="q" 
                alwaysOn 
                color="primary"
                variant="outlined"
            />
        </Filter>
    );
};

const CustomShowButton = data => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Tooltip title="Show Details">
                <Button 
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                >
                    <VisibilityIcon />  
                </Button>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="User Details"
            >
                <DialogTitle>User Details</DialogTitle>

                <DialogContent>

                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Typography variant="h6" >
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="text" >
                                {data?.record?.profile?.name}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="h6" >
                                Birthdate
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="text" >
                                {data?.record?.profile?.birthdate}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="h6" >
                                Bio
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="text" >
                                {data?.record?.profile?.bio}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="h6" >
                                Gender
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="text" >
                                {data?.record?.profile?.gender}
                            </Typography>
                        </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleCloseClick}
                        variant="contained"
                        startIcon={<CloseIcon />}
                        className={classes.dangerButton}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const CustomEditButton = data => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const dateFormatter = (strDate) => {

        var d = new Date(strDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const handleSaveUser = values => {
        values.birthdate = dateFormatter(values.birthdate);
        dataProvider
        .update('users', { id: data.record.id, data: { meta: values }} )
        .then( response => {
            setShowDialog(false);
            notify("Successfully updated the user", { type: 'success' });
            redirect('/users');
            refresh();
            setLoading(false);
        })
        .catch( error => {
            notify(error.message, { type: 'error' });
            setLoading(false);
        });
    };

    const handleSavePassword = values => {
        dataProvider
        .updatePassword('users', {id: data.record.id,  data: {meta: { password: values.password }} })
        .then( response => {
            setShowDialog(false);
            notify("Successfully updated the password", { type: 'success' });
            redirect('/users');
            refresh();
            setLoading(false);
        })
        .catch( error => {
            notify(error.message, { type: 'error' });
            setLoading(false);
        });
    };

    const transform = (val) => {
        return { meta: val}
    }

    return (
        <>
            <Tooltip title="Edit">
                <Button 
                    onClick={handleClick}
                    variant="contained"
                     color="primary"
                    className={classes.editButton}
                >
                    <EditIcon />  
                </Button>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="User Update"
            >

                <TabbedShowLayout>
                    <Tab
                        label="User Details Modification"
                    >
                        <FormWithRedirect
                            resource="users"
                            save={handleSaveUser}
                            render={
                                (
                                    {
                                        handleSubmitWithRedirect,
                                        pristine,
                                        saving
                                    }
                                ) => (
                                    <>
                                        <DialogContent>
                                            <TextInput 
                                                source="name" 
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.profile.name}
                                            />
                                            <TextInput 
                                                source="number" 
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.user.username}
                                            />
                                            <TextInput 
                                                source="email" 
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.user.email}
                                            />
                                            <DateInput 
                                                source="birthdate" 
                                                validate={[required()]}
                                                fullWidth
                                                initialValue={data.record.profile.birthdate}
                                            />
                                            <SelectInput
                                                fullWidth
                                                source="gender"
                                                choices={[
                                                    { id: 'male', name: 'Male' },
                                                    { id: 'female', name: 'Female' },
                                                ]}
                                                initialValue={data.record.profile.gender ? data.record.profile.gender.toLowerCase() : 'male'}
                                            />
                                            <SelectInput
                                                fullWidth
                                                source="role"
                                                choices={[
                                                    { id: 'user', name: 'User' },
                                                    { id: 'administrator', name: 'Administrator' },
                                                ]}
                                                initialValue={data.record.user.role}
                                            />
                                            <SelectInput
                                                fullWidth
                                                source="status"
                                                choices={[
                                                    { id: 'activated', name: 'Activated' },
                                                    { id: 'deactivated', name: 'Deactivated' },
                                                    { id: 'blocked', name: 'Blocked' },
                                                    { id: 'deleted', name: 'Deleted' },
                                                ]}
                                                initialValue={data.record.user.status}
                                                validate={[required()]}
                                            />
                                        </DialogContent>
                                        
            
                                        <DialogActions>
                                            <SaveButton
                                                handleSubmitWithRedirect={
                                                    handleSubmitWithRedirect
                                                }
                                                pristine={pristine}
                                                saving={saving}
                                                disabled={loading}
                                            />
                                            <Button
                                                onClick={handleCloseClick}
                                                variant="contained"
                                                startIcon={<CloseIcon />}
                                                className={classes.dangerButton}
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>   
                                        
                                    </>
                                )
                            }
                        /> 
                    </Tab>
                    <Tab
                        label="Password Modification"
                    >
                        <FormWithRedirect
                            resource="users"
                            save={handleSavePassword}
                            render={
                                (
                                    {
                                        handleSubmitWithRedirect,
                                        pristine,
                                        saving
                                    }
                                ) => (
                                    <>
                                        <DialogContent>
                                            <PasswordInput 
                                                source="password" 
                                                validate={[required()]}
                                                fullWidth 
                                            />
                                        </DialogContent>
                                        
            
                                        <DialogActions>
                                            <SaveButton
                                                handleSubmitWithRedirect={
                                                    handleSubmitWithRedirect
                                                }
                                                pristine={pristine}
                                                saving={saving}
                                                disabled={loading}
                                            />
                                            <Button
                                                onClick={handleCloseClick}
                                                variant="contained"
                                                startIcon={<CloseIcon />}
                                                className={classes.dangerButton}
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>   
                                        
                                    </>
                                )
                            }
                        /> 
                    </Tab>
                </TabbedShowLayout>
            </Dialog>
        </>
    );
};

export const UserList = props => {

    return (
        <List {...props} filters={<UserFilter />} perPage={25} title="Users List" bulkActionButtons={false} >
            <Datagrid>
                <TextField 
                    source="user.uid" 
                    label="UID"    
                />
                <TextField 
                    source="user.created" 
                    label="Created"    
                />
                <TextField 
                    source="user.modified" 
                    label="Modified"    
                />
                <TextField 
                    source="user.deleted" 
                    label="Deleted"    
                />
                <TextField 
                    source="user.username" 
                    label="Mobile Number"    
                />
                <TextField 
                    source="user.email" 
                    label="Email"    
                />
                <FunctionField 
                    source="user.login_by" 
                    label="Registered via"
                    render={record => record ? record?.user?.login_by?.replace('_', ' ') : 'N/A'}    
                />
                <TextField 
                    source="user.status" 
                    label="Status"    
                />
                <TextField 
                    source="user.role" 
                    label="Role"    
                />
                <CustomEditButton />
                <CustomShowButton />
            </Datagrid>
        </List>
    );
}

export default UserList;