import React from "react";
import { 
    Create,
    SimpleForm, 
    TextInput, 
    NumberInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
    required,
} from 'react-admin';

export const SubscriptionTypeCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = ({ data }) => {
        notify("Successfully created subscription type", { type: 'success' })
        redirect('/st');
        refresh();
    };

    const transform = data => {
        return { meta: data }
    }

    return (
        <Create onSuccess={onSuccess} transform={transform} submitOnEnter={false} {...props}>
            <SimpleForm>
                <TextInput 
                    source="name" 
                    validate={[required()]}
                    fullWidth 
                />
                <NumberInput 
                    source="amount" 
                    validate={[required()]}
                    fullWidth 
                    min={0}
                />
                <NumberInput 
                    source="discounted_amount" 
                    validate={[required()]}
                    fullWidth 
                    min={0}
                />
                <SelectInput
                    fullWidth
                    source="measurement"
                    choices={[
                        { id: 'day', name: 'Day' },
                        { id: 'week', name: 'Week' },
                        { id: 'month', name: 'Month' },
                        { id: 'year', name: 'Year' },
                    ]}
                    initialValue={'month'}
                />
                <NumberInput 
                    source="value" 
                    validate={[required()]}
                    fullWidth 
                    min={1}
                />
                <SelectInput
                    fullWidth
                    source="status"
                    choices={[
                        { id: 'activated', name: 'Activated' },
                        { id: 'deactivated', name: 'Deactivated' }
                    ]}
                    initialValue={'activated'}
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    );
};

export default SubscriptionTypeCreate;