import { stringify } from 'query-string';
import constants from '../utilities/constants';
import httpClient from '../utilities/httpClient';

const apiUrl = `${constants.admin_url}/analytics/`

export default {
    getUserCount: (resource, params) => {
        const query = {
            status: params.filter.status
        }
        const url = `${apiUrl}users/count?${stringify(query)}`;
        return httpClient(url)
        .then( 
            ( { json }) => 
            {
                return {
                    data: json.meta.count,
                    total: 1
                }
            }
        ).catch(
            () => {
                return {
                    data: 0,
                    total: 0
                }
            }
        );
    },
    getLoginCount: (resource, params) => {
        const query = {
            status: params.filter.status
        }
        const url = `${apiUrl}logins/count?${stringify(query)}`;

        return httpClient(url)
        .then( 
            ( { json }) => 
            {
                return {
                    data: json.meta.count,
                    total: 1
                }
            }
        ).catch(
            () => {
                return {
                    data: 0,
                    total: 0
                }
            }
        );
    },
    getSubscribedUserCount: (resource, params) => {
        const url = `${apiUrl}users/count/premium`;

        return httpClient(url)
        .then( 
            ( { json }) => 
            {
                return {
                    data: json.meta.count,
                    total: 1
                }
            }
        ).catch(
            () => {
                return {
                    data: 0,
                    total: 0
                }
            }
        );
    },
    getSubscriptionIncome: (resource, params) => {
        const url = `${apiUrl}subscriptions/income`;

        return httpClient(url)
        .then( 
            ( { json }) => 
            {
                return {
                    data: json.meta.amount,
                    total: 1
                }
            }
        ).catch(
            () => {
                return {
                    data: 0,
                    total: 0
                }
            }
        );
    },
    getUserCountByCoverage: (resource, params) => {
        const query = {
            coverage: params.filter.coverage
        }
        const url = `${apiUrl}users/count/time?${stringify(query)}`;

        return httpClient(url)
        .then( 
            ( { json }) => 
            {
                return {
                    data: json.meta.count,
                    total: 1
                }
            }
        ).catch(
            () => {
                return {
                    data: 0,
                    total: 0
                }
            }
        );
    },
    getLoginCountByCoverage: (resource, params) => {
        const query = {
            coverage: params.filter.coverage
        }
        const url = `${apiUrl}logins/count/time?${stringify(query)}`;

        return httpClient(url)
        .then( 
            ( { json }) => 
            {
                return {
                    data: json.meta.count,
                    total: 1
                }
            }
        ).catch(
            () => {
                return {
                    data: 0,
                    total: 0
                }
            }
        );
    },
};
