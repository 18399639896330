import React, { useState }  from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    SearchInput,
    Filter,
} from 'react-admin';
import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dangerButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    deactivateButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    activateButton: {
        color: 'white',
        backgroundColor: 'teal'
    },
    editButton: {
        color: 'white',
        backgroundColor: '#fbc02d'
    },
    media: {
        height: 480
    }
  }));

const CustomFilter = (props) => {
    return ( 
        <Filter {...props}>

            <SearchInput 
                fullWidth
                source="q" 
                alwaysOn 
                color="primary"
                variant="outlined"
            />
        </Filter>
    );
};

export const ReportList = props => {

    return (
        <List {...props} filters={<CustomFilter />} perPage={25} title="Reports List" bulkActionButtons={false} >
            <Datagrid>
                <TextField 
                    label="Reporter UID"
                    source="owner.uid"     
                />
                <TextField 
                    label="Reporter Username"
                    source="owner.username"     
                />
                <TextField 
                    label="Reporter Email"
                    source="owner.email"     
                />
                <TextField 
                    label="Reported UID"
                    source="block.uid" 
                />
                <TextField 
                    label="Reported Username"
                    source="block.username" 
                />
                <TextField 
                    label="Reported Email"
                    source="block.email" 
                />
                <TextField 
                    label="Reason"
                    source="reason" 
                />
            </Datagrid>
        </List>
    );
}

export default ReportList;