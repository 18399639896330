import * as React from 'react';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CardWithIcon from './CardWithIcon';

const LoginCount = ({ value }) => {
    return (
        <CardWithIcon
            to="/users"
            icon={LockOpenIcon}
            title="Logins"
            subtitle={value}
        />
    );
};

export default LoginCount;