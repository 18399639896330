import React, { useState }  from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    SearchInput,
    Filter,
    FormWithRedirect,
    TabbedShowLayout,
    Tab,
    TextInput,
    SaveButton,
    required,
    useDataProvider,
    useRedirect,
    useNotify,
    useRefresh,
    SelectInput,
    DateTimeInput
} from 'react-admin';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Tooltip,
    Card,
    CardMedia,
    CardActions,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import dateFormatter from '../../utilities/dateFormatter'
import constants from '../../utilities/constants'

const useStyles = makeStyles((theme) => ({
    dangerButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    deactivateButton: {
        color: 'white',
        backgroundColor: 'red'
    },
    activateButton: {
        color: 'white',
        backgroundColor: 'teal'
    },
    editButton: {
        color: 'white',
        backgroundColor: '#fbc02d'
    },
    media: {
        height: 480
    }
  }));

const CustomFilter = (props) => {
    return ( 
        <Filter {...props}>

            <SearchInput 
                fullWidth
                source="q" 
                alwaysOn 
                color="primary"
                variant="outlined"
            />
        </Filter>
    );
};

const CustomShowButton = data => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        data?.record?.verification?.url && <>
            <Tooltip title="Show Verification Image">
                <Button 
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                >
                    <VisibilityIcon />  
                </Button>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Verification Image"
            >
                <Card className={classes.root}>
                    <CardMedia
                        className={classes.media}
                        image={data.record.verification.url + `?jwt=${localStorage.access_token}`}
                        title={data.record.name}
                    />
                    <CardActions>
                        <Button
                            onClick={handleCloseClick}
                            variant="contained"
                            startIcon={<CloseIcon />}
                            className={classes.dangerButton}
                        >
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
        </> || <></>
    );
};

const CustomEditButton = data => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSave = values => {
        values.expiration = dateFormatter(values.expiration)
        dataProvider
        .update('uv', { id: data.record.id, data: { meta: values }} )
        .then( response => {
            setShowDialog(false);
            notify("Successfully updated the user verification", { type: 'success' });
            redirect('/uv');
            refresh();
            setLoading(false);
        })
        .catch( error => {
            notify(error.message, { type: 'error' });
            setLoading(false);
        });
    };

    return (
        <>
            <Tooltip title="Edit">
                <Button 
                    onClick={handleClick}
                    variant="contained"
                     color="primary"
                    className={classes.editButton}
                >
                    <EditIcon />  
                </Button>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="User Verification Update"
            >

                <TabbedShowLayout>
                    <Tab
                        label="User Verification Details Modification"
                    >
                        <FormWithRedirect
                            resource="ads"
                            save={handleSave}
                            render={
                                (
                                    {
                                        handleSubmitWithRedirect,
                                        pristine,
                                        saving
                                    }
                                ) => (
                                    <>
                                        <DialogContent>
                                            <TextInput 
                                                source="remarks" 
                                                validate={[required()]}
                                                multiline
                                                fullWidth 
                                                resettable
                                                initialValue={data.record.verification.remarks}
                                            />
                                            <DateTimeInput 
                                                label="Expiration"
                                                source="expiration" 
                                                validate={[required()]}
                                                fullWidth 
                                                options={{ format: 'DD/MM/YYYY HH:MM:SS', ampm: false, clearable: true }}
                                            />
                                            <SelectInput 
                                                source="status" 
                                                validate={[required()]}
                                                fullWidth 
                                                initialValue={data.record.verification.status}
                                                choices={constants.uvStatus}
                                            />
                                        </DialogContent>
                                        
            
                                        <DialogActions>
                                            <SaveButton
                                                handleSubmitWithRedirect={
                                                    handleSubmitWithRedirect
                                                }
                                                pristine={pristine}
                                                saving={saving}
                                                disabled={loading}
                                            />
                                            <Button
                                                onClick={handleCloseClick}
                                                variant="contained"
                                                startIcon={<CloseIcon />}
                                                className={classes.dangerButton}
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>   
                                        
                                    </>
                                )
                            }
                        /> 
                    </Tab>
                </TabbedShowLayout>
            </Dialog>
        </>
    );
};

export const VerificationList = props => {

    return (
        <List {...props} filters={<CustomFilter />} perPage={25} title="User Verifications List" bulkActionButtons={false} >
            <Datagrid>
                <TextField 
                    label="User UID"
                    source="user.uid"     
                />
                <TextField 
                    label="Created"
                    source="verification.created"     
                />
                <TextField 
                    label="Modified"
                    source="verification.modified"     
                />
                <TextField 
                    label="Expiration"
                    source="verification.expiration" 
                />
                <TextField 
                    label="Username"
                    source="user.username" 
                />
                <TextField 
                    label="User Email"
                    source="user.email" 
                />
                <TextField 
                    label="Remarks"
                    source="verification.remarks"     
                />
                <TextField 
                    label="Status"
                    source="verification.status"     
                />
                <CustomEditButton />
                <CustomShowButton />
            </Datagrid>
        </List>
    );
}

export default VerificationList;